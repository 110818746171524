(function(){

	var alvo = $('[data-trocar-active="true"]');

	var gatilhos = alvo.find('a');

	gatilhos.each(function(){

		var isso = $(this);

		isso.click(function(){
			event.preventDefault();
			gatilhos.removeClass('active');
			$(this).addClass('active');
		});

	});

})();